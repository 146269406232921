<template>
  <div class="sandbox">
    <div class="left">
      <a-card
        v-for="(item, index) in docModuleList"
        :key="index"
        :title="item.module"
        style="width: 100%; margin-top: 20px"
      >
        <p
          class="ff-list"
          :class="{ active: e.summary == leftActive }"
          v-for="(e, i) in item.docItems"
          :key="i"
          @click="selectButton(e, i)"
        >
          {{ e.summary }} {{ e.version }}
        </p>
      </a-card>
    </div>
    <div class="right">
      <div class="ff-content" v-if="docItems.summary == this.leftActive">
        <h2>
          <a-icon type="bars" style="margin-right: 10px" /><span>{{
            docItems.summary
          }}</span>
        </h2>
        <div class="xian"></div>
        <div class="ff-block">
          <div class="xian-title"><span>概述</span></div>
          <p>1、用户对开发者进行应用授权后，开发者可以帮助用户完成相应的业务逻辑。</p>
          <p>2、授权采用标准的OAuth 2.0流程。</p>
        </div>
        <h2>授权流程</h2>
        <img class="ff-img" src="@/assets/auth.png" alt="">
        <div class="ff-block">
          <div class="xian-title"><span>快速接入</span></div>
        </div>
        <h2>第一步：应用授权URL拼装</h2>
        <p>拼接规则:</p>
        <p>https://auth.open.ecmoho.com/oauth2/appToAppAuth?</p>
        <p>app_id=2019032617262200001&redirect_uri=http%3a%2f%2flocalhost%3a8087%2foauth2callback</p>  
        <p>参数说明：</p> 
        <a-table :columns="columnsParams" :data-source="docItems.paramsTable" :pagination="false"></a-table>
        <h2>第二步：获取code</h2>
        <p>授权成功后，客户端会跳转至开发者定义的回调页面（即redirect_uri参数对应的url），在回调页面请求中会带上当次授权的授权码code和开发者的app_id，示例如下：</p>
        <p>https://auth.open.ecmoho.com/oauth2callback?app_id=2015101400446982&code=ca34ea491e7146cc87d25fca24c4cD11</p>
        <h2>第三步：使用code换取app_auth_token</h2>
        <p>接口名称：open.auth.token.app</p>
        <p>开发者通过code可以换取app_auth_token、授权用户的userId。</p> 
        <p>注意:应用授权的code唯一，code使用一次后失效，有效期24小时； app_auth_token永久有效。</p>
        <p>请求参数说明</p>
        <a-table :columns="columnsParams" :data-source="docItems.paramsExplain" :pagination="false"></a-table>
        <p>接口请求SDK示例</p>
        <pre>
          @GetMapping("oauth2callback")
            @ResponseBody
            public String callback(HttpServletRequest servletRequest, HttpServletResponse servletResponse) {
                String app_id = servletRequest.getParameter("app_id");
                String code = servletRequest.getParameter("code");
                OpenAuthTokenAppRequest request = new OpenAuthTokenAppRequest();    
                OpenAuthTokenAppModel model = new OpenAuthTokenAppModel();
                model.setCode(code);
                model.setGrant_type("authorization_code");
                request.setBizModel(model);
                // 根据code获取token
                OpenAuthTokenAppResponse response = openClient.execute(request);
                if (response.isSuccess()) {
                    // 成功拿到token，开发者在这里保存token信息
                    // 后续使用token进行接口访问
                    log.info("授权成功，body:{}", response.getBody());
                }
                return response.getBody();
            }          
        </pre>
        <h2>同步响应参数说明</h2>
        <a-table :columns="columnsParams" :data-source="docItems.responseParams" :pagination="false"></a-table>
        <h2>同步响应结果示例</h2>
        <pre>
          {
              "open_auth_token_app_response": {
                  "code": "10000",
                  "msg": "Success",
                  "app_auth_token": "88e68196d2359667f0dc8136e6c86803",
                  "app_refresh_token": "856faf8d77d3b985c1073557ce6ea724",
                  "expires_in": -1,
                  "re_expires_in": -3,
                  "user_id": "1"
              },
              "sign": "xxx"
          }          
        </pre>
        <h2>刷新token</h2>
        <pre>
          OpenAuthTokenAppRequest request = new OpenAuthTokenAppRequest();
          OpenAuthTokenAppModel model = new OpenAuthTokenAppModel();
          model.setGrant_type("refresh_token");
          model.setRefresh_token("856faf8d77d3b985c1073557ce6ea724");
          request.setBizModel(model);
          OpenAuthTokenAppResponse response = openClient.execute(request);
          if (response.isSuccess()) {
              // 成功拿到token，开发者在这里保存token信息
              // 后续使用token进行接口访问
              log.info("换取token成功，body:{}", response.getBody());
          }          
        </pre>
      </div>
    </div>
  </div>
</template>
<script>
const columns = [
  {
    title: "参数",
    dataIndex: "name",
    scopedSlots: { customRender: "name" },
  },
  {
    title: "值",
    className: "column-money",
    dataIndex: "money",
    scopedSlots: { customRender: "value" },
  },
];

const columnsIP = [
  {
    title: "环境",
    dataIndex: "title",
  },
  {
    title: "请求地址",
    dataIndex: "ip",
  },
];

const columnsParams = [
  {
    title: "参数",
    dataIndex: "name",
  },
  {
    title: "参数名称",
    dataIndex: "display",
  },  
  {
    title: "类型",
    dataIndex: "type",
  },
  {
    title: "必填",
    dataIndex: "required",
  },
  {
    title: "描述",
    dataIndex: "description",
  },
  {
    title: "范例",
    dataIndex: "example",
  },
];

const data = [
  {
    key: "1",
    name: "John Brown",
    money: "￥300,000.00",
    address: "New York No. 1 Lake Park",
  },
  {
    key: "2",
    name: "Jim Green",
    money: "￥1,256,000.00",
    address: "London No. 1 Lake Park",
  },
];
import { getDocBaseInfoApi, docinfoOrderApi } from "@/serve/Interface"
import { createResponseCode } from "@/tool"
export default {
  data() {
    return {
      arrayObj: {
        leftSelect: [],
      },
      docModuleList: [{
        module: '签名专区',
        docItems: [
          {
            summary: '第三方应用授权',
            paramsTable: [{
              name: 'app_id',
              display: '开发者应用的AppId',
              type: 'String',
              required: '是',
              description: '开发者应用的AppId',
              example: '2015101400446982'
            },{
              name: 'redirect_uri',
              display: '回调页面',
              type: 'String',
              required: '是',
              description: '参数需要UrlEncode',
              example: 'http%3A%2F%2Fexample.com'
            }],
            paramsExplain: [
              {
                name: 'grant_type',
                display: '授权类型',
                type: 'String',
                required: '是',
                description: '如果使用code换取token，则为authorization_code，如果使用refresh_token换取新的token，则为refresh_token',
                example: 'authorization_code'
              },
              {
                name: 'code',
                display: '授权码',
                type: 'String',
                required: '否',
                description: '与refresh_token二选一，用户对应用授权后得到，即第一步中开发者获取到的code值',
                example: 'bf67d8d5ed754af297f72cc482287X62'
              },
              {
                name: 'refresh_token',
                display: '刷新令牌',
                type: 'String',
                required: '否',
                description: '	与code二选一，可为空，刷新令牌时使用',
                example: '201510BB0c409dd5758b4d939d4008a525463X62'
              }
            ],
            responseParams: [
              {
                name: 'app_auth_token',
                display: '用户授权令牌',
                type: 'String',
                required: '是',
                description: '通过该令牌来帮助用户发起请求，完成业务',
                example: '856faf8d77d3b985c1073557ce6ea724'
              },
              {
                name: 'user_id',
                display: '授权用户的ID',
                type: 'String',
                required: '是',
                description: '授权者id',
                example: '1'
              },
              {
                name: 'expires_in',
                display: '令牌有效期',
                type: 'Long',
                required: '是',
                description: '负值表示永久有效',
                example: '-1'
              },
              {
                name: 're_expires_in',
                display: '刷新令牌有效期',
                type: 'Long',
                required: '是',
                description: '负值表示永久有效',
                example: '-3'
              },
              {
                name: 'app_refresh_token',
                display: '刷新令牌时使用',
                type: 'String',
                required: '是',
                description: '刷新令牌后，我们会保证老的app_auth_token从刷新开始10分钟内可继续使用，请及时替换为最新token',
                example: '88e68196d2359667f0dc8136e6c86803'
              }
            ]
          }
        ]
      }],
      docItems: {},
      data,
      columns,
      columnsIP,
      columnsParams,
      leftActive: "第三方应用授权",
    };
  },
  mounted() {
     this.docItems = this.docModuleList[0].docItems[0]
  },
  methods: {
    selectButton(e, i) {
      this.docItems = e
      this.leftActive = e.summary
      window.scrollTo(0, 0)
    },
  },
};
</script>
<style lang="scss">
.sandbox {
  width: 1200px;
  height: calc(100% - 60px);
  margin: 0 auto;
  display: flex;
  .left {
    width: 240px;
    padding-top: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    .ff-list {
      cursor: pointer;
      &.active {
        color: #1890ff;
      }
    }
  }
  .right {
    width: calc(100% - 240px);
    border-left: 1px solid #eee;
    padding-top: 20px;
    padding-left: 20px;
    .ff-img{
      width: 100%;
    }
    h2{
      margin: 20px 0;
    }
    p{
      margin: 20px 0;
    }
    pre{
      width: 100%;
      margin: 20px 0;
      word-wrap: break-word;
      font-size: 12px;
      background: #eee;
      padding: 10px 0;
    }
    .xian {
      width: 100%;
      height: 1px;
      background: #eee;
      margin: 20px 0;
    }
    .xian-title {
      position: relative;
      font-size: 20px;
      margin: 20px 0;
      span {
        position: relative;
        z-index: 2;
        background: #fff;
        padding: 0 5px;
        margin: 0 20px;
      }
      &::before {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        background: #eee;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
      }
    }
  }
  .ff-set {
    margin: 20px 0;
  }
}
</style>
